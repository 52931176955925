import 'es6-shim';
import React from 'react';

import { ConfigProvider } from 'antd';
import ConsumerAuthProvider from 'context/ConsumerAuthProvider';
import FeatureFlagsProvider from 'context/FeatureFlagsProvider';
import KioskProvider from 'context/KioskProvider';
import NetworkProvider from 'context/NetworkProvider';
import PropTypes from 'prop-types';
import { Routes, Route } from 'react-router-dom';

import { USER_TYPE } from 'constants/config.constants';

import { dashboardTheme } from 'config/theme.config';

import { useConsumerAuth } from 'hooks/useConsumerAuth';

import Loader from 'components/Loader';
import LoggingErrorBoundary from 'components/LoggingErrorBoundary';
import ResetPasswordForm from 'components/Login/ResetPasswordForm';
import PaymentOptions from 'components/Storefront/Account/PaymentOptions';

ConfigProvider.config({ prefixCls: 'cuboh' });

const StorefrontRegister = Loader(() =>
	import('containers/StorefrontRegister'),
);
const StorefrontAccount = Loader(() => import('containers/StorefrontAccount'));
const OrderHistory = Loader(() =>
	import('components/Storefront/Account/OrderHistory'),
);
const Profile = Loader(() => import('components/Storefront/Account/Profile'));
const RegistrationForm = Loader(() =>
	import('components/Storefront/Register/RegistrationForm'),
);
const VerifyEmail = Loader(() =>
	import('components/Storefront/Register/VerifyEmail'),
);
const AuthForm = Loader(() => import('containers/AuthForm'));
const StorefrontLayout = Loader(() => import('layouts/StorefrontLayout'));
const Exception = Loader(() => import('exceptions/404'));
const LoginForm = Loader(() => import('components/Login/LoginForm'));
const MenuCheckout = Loader(() => import('containers/MenuCheckout'));

const ForgotPassword = Loader(() => import('components/Login/ForgotPassword'));
const ConsumerChangePassword = Loader(() =>
	import('components/Storefront/Account/ChangePassword'),
);
const StorefrontLocations = Loader(() =>
	import('containers/StorefrontLocations'),
);
const KioskHome = Loader(() =>
	import('components/MenuCheckout/Kiosk/Modals/KioskHome'),
);

App.propTypes = {
	container: PropTypes.node,
	layout: PropTypes.node,
};

function App() {
	// Separate App for any route for /store*
	return (
		<FeatureFlagsProvider>
			<ConfigProvider theme={dashboardTheme}>
				<KioskProvider>
					<NetworkProvider>
						<ConsumerAuthProvider>
							<LoggingErrorBoundary>
								<Routes>
									{/* Login Routes */}
									<Route
										path={'/'}
										element={
											<AuthForm theme="light">
												<LoginForm
													authHook={useConsumerAuth}
													userType={USER_TYPE.consumer}
												/>
											</AuthForm>
										}
									/>
									<Route
										path={'/store/login'}
										element={
											<AuthForm theme="light">
												<LoginForm
													authHook={useConsumerAuth}
													userType={USER_TYPE.consumer}
												/>
											</AuthForm>
										}
									/>
									<Route
										path={'/store/forgot-password'}
										element={
											<AuthForm theme="light">
												<ForgotPassword userType={USER_TYPE.consumer} />
											</AuthForm>
										}
									/>
									<Route
										path={'/store/reset-password'}
										element={
											<AuthForm theme="light">
												<ResetPasswordForm userType={USER_TYPE.consumer} />
											</AuthForm>
										}
									/>

									{/* Registration Routes */}
									<Route
										path={'/store/register'}
										element={
											<StorefrontRegister>
												<RegistrationForm />
											</StorefrontRegister>
										}
									/>
									<Route
										path={'/store/verify-email'}
										element={
											<StorefrontRegister>
												<VerifyEmail />
											</StorefrontRegister>
										}
									/>

									{/* Storefront Account & Checkout & Landing Page */}
									{[
										{
											path: '/store/account/order-history',
											render: () => (
												<StorefrontAccount>
													<OrderHistory />
												</StorefrontAccount>
											),
										},
										{
											path: '/store/account/change-password',
											render: () => (
												<StorefrontAccount>
													<ConsumerChangePassword />
												</StorefrontAccount>
											),
										},
										{
											path: '/store/account/payments',
											render: () => (
												<StorefrontAccount>
													<PaymentOptions>
														<PaymentOptions.NoneAlert />
														<PaymentOptions.CardList />
														<PaymentOptions.AddButton />
													</PaymentOptions>
												</StorefrontAccount>
											),
										},
										{
											path: '/store/account',
											render: () => (
												<StorefrontAccount>
													<Profile />
												</StorefrontAccount>
											),
										},
										{
											path: '/store/kiosk',
											render: () => <KioskHome />,
										},
										{
											path: '/store/:companyName/',
											render: () => <MenuCheckout />,
										},
										{
											path: '/store/:companyName/:merchantName',
											render: () => <MenuCheckout />,
										},
										{
											path: '/locations/:parentCompanyPath',
											render: () => <StorefrontLocations />,
										},
									].map(({ path, render }) => (
										<Route
											key={path}
											path={path}
											exact
											element={<StorefrontLayout>{render()}</StorefrontLayout>}
										/>
									))}
									<Route element={<Exception />} />
								</Routes>
							</LoggingErrorBoundary>
						</ConsumerAuthProvider>
					</NetworkProvider>
				</KioskProvider>
			</ConfigProvider>
		</FeatureFlagsProvider>
	);
}

export default App;
