import { createCoreAxiosInstance } from 'createAxiosInstance';

export const registerConsumer = async (payload) => {
	const axios = await createCoreAxiosInstance();
	const response = await axios.post('/api/v2/storefront/register', payload);
	return response.data;
};

/**
 * Check if an email be used for registration
 * The /api/v2/storefront/register endpoint tells user if the email is taken
 *
 * @param {string} email: The email to verify
 * @returns true if the email can be used for consumer registration
 */
export const checkEmailIsAvailable = async (email) => {
	const axios = await createCoreAxiosInstance();
	return axios
		.post('/api/v2/storefront/register', { email })
		.then(() => true)
		.catch((e) => {
			const emailErrors = e?.response?.data?.email ?? [];
			return !emailErrors.includes(`${email} is already taken`);
		});
};

export const sendRegistrationLink = async (email) => {
	const axios = await createCoreAxiosInstance();
	return axios.post('/api/v2/storefront/register/send-email', { email });
};

export const verifyToken = async (token) => {
	const axios = await createCoreAxiosInstance();
	return axios.post('/api/v2/storefront/register/verify', { token });
};

const formatAuthData = ({ profile, ...rest }) => {
	return {
		id: rest.id,
		firstName: rest.first_name,
		lastName: rest.last_name,
		username: rest.username,
		address: profile.address,
		address2: profile.address2,
		latitude: profile.address_latitude,
		longitude: profile.address_longitude,
		city: profile.city,
		country: profile.country,
		phoneNumber: profile.phone_number,
		state: profile.state,
		meta: profile.user_meta,
		zipCode: profile.zip_code,
	};
};

export const fetchUserData = async () => {
	try {
		const axiosInstance = await createCoreAxiosInstance();
		const { data } = await axiosInstance.get('/api/v2/storefront/user');
		return formatAuthData(data);
	} catch (error) {
		throw new Error(error.message);
	}
};

export const updateUser = async (values) => {
	const axiosInstance = await createCoreAxiosInstance();
	const { data } = await axiosInstance.patch('api/v2/storefront/user', values);
	return formatAuthData(data);
};

export const formatUpdateUserErrors = (errors) => {
	const userErrors = ['first_name', 'last_name', 'username'].map(
		(fieldName) => ({
			name: fieldName,
			errors: errors[fieldName] || [],
		}),
	);
	const profileErrors = ['phone_number'].map((fieldName) => ({
		name: fieldName,
		errors: errors?.profile?.[fieldName] || [],
	}));
	return [...userErrors, ...profileErrors];
};

export const createPaymentOption = async () => {
	const axios = await createCoreAxiosInstance();
	const response = await axios.post('/api/v2/storefront/user/payment-options');
	return response.data;
};

export const fetchPaymentOptions = async () => {
	const axios = await createCoreAxiosInstance();
	const response = await axios.get('/api/v2/storefront/user/payment-options');
	return response.data;
};

export const detachPaymentOption = async (paymentMethodID) => {
	const axios = await createCoreAxiosInstance();
	await axios.delete(
		`/api/v2/storefront/user/payment-options/${paymentMethodID}`,
	);
};

export const setDefaultPaymentOption = async (paymentMethodID) => {
	const axios = await createCoreAxiosInstance();
	await axios.patch(`/api/v2/storefront/user/payment-options/default`, {
		payment_method_id: paymentMethodID,
	});
};

export const fetchOrders = async ({ limit = 25, offset = 0 }) => {
	const axios = await createCoreAxiosInstance();
	const response = await axios.get(
		`/api/v2/storefront/orders?limit=${limit}&offset=${offset}`,
	);

	return response.data;
};
