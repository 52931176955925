import {
	atLeastOneLowerCase,
	atLeastOneNumber,
	atLeastOneSpecialCharacter,
	atleastOneUpperCase,
	atLeastTenCharactersLong,
	noEmptySpaces,
} from 'utils/helpers';

export const EMAIL_AVAILABLE = {
	label: 'This email is available',
	status: true,
};

export const EMAIL_VALIDATING = {
	label: 'Checking if your email is taken...',
	status: 'validating',
};

export const EMAIL_TAKEN = {
	label: 'This email is already taken',
	status: false,
};

export const PERMISSION_ERROR =
	"You don't have permission to perform this action.";

export const SET_DATA = 'SET_DATA';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_ERROR = 'SET_ERROR';

export const SET_PAYMENT_OPTIONS = 'SET_PAYMENT_OPTIONS';
export const IS_PAYMENTS_LOADING = 'IS_PAYMENTS_LOADING';
export const PAYMENTS_HAS_ERROR = 'PAYMENTS_HAS_ERROR';
export const PAYMENTS_DATA = 'PAYMENTS_DATA';
export const DEFAULT_ID = 'DEFAULT_ID';
export const EDIT_DROPOFF_ADDRESS = 'EDIT_DROPOFF_ADDRESS';

export const DEFAULT_DROPOFF_ADDRESS = {
	city: '',
	state: '',
	street: '',
	unit: '',
	zip_code: '',
	latitude: '',
	longitude: '',
	country: '',
};

export const PASSWORD_RULES = [
	{
		condition: noEmptySpaces,
		label: 'No empty spaces',
	},
	{
		condition: atLeastTenCharactersLong,
		label: 'At least 10 characters long',
	},
	{
		condition: (value) =>
			atLeastOneSpecialCharacter(value) || atLeastOneNumber(value),
		label: 'At least 1 special character or number',
	},
	{
		condition: (value) =>
			atLeastOneLowerCase(value) && atleastOneUpperCase(value),
		label: 'At least 1 upper and lower case',
	},
];
