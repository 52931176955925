export const KIOSK = 'kiosk';

export const TIMEOUT = 60_000;
export const PROMPT_BEFORE_IDLE = 12_000;
export const KIOSK_REFRESH_TIME = '04:00';
export const ACTIVE = 'Active';
export const PROMPTED = 'Prompted';
export const IDLE = 'Idle';

// local storage keys
export const KIOSK_REDIRECT_URL = 'redirect_url';
export const KIOSK_COMPANY_ID = 'company_id';
export const KIOSK_TERMINAL_ID = 'terminal_id';

// reducer actions
export const EDIT_KIOSK_COMPANY_ID = 'EDIT_KIOSK_COMPANY_ID';
export const EDIT_KIOSK_TERMINAL_ID = 'EDIT_KIOSK_TERMINAL_ID';
export const EDIT_KIOSK_REDIRECT_URL = 'EDIT_KIOSK_REDIRECT_URL';
export const EDIT_KIOSK_SESSION_ID = 'EDIT_KIOSK_SESSION_ID';
export const CLEAR_KIOSK_KEYS = 'CLEAR_KIOSK_KEYS';
export const CACHE_STOREFRONT_IMAGES = 'CACHE_STOREFRONT_IMAGES';

// Manage Kiosk Views
export const OVERVIEW = 'OVERVIEW';
export const LOCATION = 'LOCATION';
export const READERS = 'READERS';
export const STORE_CODE = 'STORE_CODE';
export const FAQ = 'FAQ';

export const ADMIN_MODAL_STEPS = {
	unlocked: 0,
	settings: 1,
	orderConsole: 2,
};

export const FONT_SIZE = (size) => {
	if (window.screen.availWidth < 950) {
		return size;
	}
	return size + 4;
};
