import React, { useRef } from 'react';

import PropTypes from 'prop-types';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

Fader.propTypes = {
	children: PropTypes.node,
	value: PropTypes.any,
};

export default function Fader({ children, value }) {
	const ref = useRef(null);
	return (
		<SwitchTransition mode={'out-in'}>
			<CSSTransition nodeRef={ref} key={value} classNames="fader" timeout={400}>
				<div ref={ref} className={value}>
					{children}
				</div>
			</CSSTransition>
		</SwitchTransition>
	);
}
