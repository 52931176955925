import React from 'react';

import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';

import Fader from 'components/Fader';

BooleanIcon.defaultProps = {
	renderTrue: CheckCircleFilled,
	renderFalse: CloseCircleFilled,
	label: '',
};

BooleanIcon.propTypes = {
	renderTrue: PropTypes.object,
	renderFalse: PropTypes.object,
	condition: PropTypes.bool,
	label: PropTypes.string,
};

export default function BooleanIcon({
	renderTrue,
	renderFalse,
	condition,
	label,
}) {
	const Icon = condition ? renderTrue : renderFalse;
	const className = condition ? 'text--success' : 'text--danger';

	return (
		<Fader value={className}>
			<Icon className={className} />
			<span style={{ marginLeft: '10px' }} className={className}>
				{label}
			</span>
		</Fader>
	);
}
