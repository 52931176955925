import { useEffect, useState } from 'react';

import { useNetworkState } from '@uidotdev/usehooks';
import dayjs from 'dayjs';

import { ENVIRONMENT } from 'constants/config.constants';
import { LOG_LEVEL } from 'constants/constants';
import {
	KIOSK_COMPANY_ID,
	KIOSK_REDIRECT_URL,
} from 'constants/kiosk.constants';

import { logToCore } from 'utils/helpers';

import { useKiosk } from 'hooks/useKiosk';
import { useRouter } from 'hooks/useRouter';

export default function useProvideNetwork() {
	const { isKiosk } = useKiosk();
	const network = useNetworkState();
	const router = useRouter();
	const [networkDisconnected, setNetworkDisconnected] = useState(false);
	const [disconnectData, setDisconnectData] = useState({});

	useEffect(() => {
		if (
			process.env.REACT_APP_ENVIRONMENT !== ENVIRONMENT.production ||
			!isKiosk
		) {
			return;
		}

		// disconnected from network
		if (!network.online) {
			setDisconnectData({
				type: network.type,
				downlink: network.downlink,
				downlinkMax: network.downlinkMax,
				effectiveType: network.effectiveType,
				rtt: network.rtt,
				disconnectTimeUnix: dayjs().unix(),
				disconnectTimeUTC: dayjs().toString(),
			});
			setNetworkDisconnected(true);
		} else {
			const disconnectedTimeInSeconds = disconnectData?.disconnectTimeUnix
				? dayjs().unix() - disconnectData?.disconnectTimeUnix
				: 0;

			const data = {
				...disconnectData,
				disconnectedTimeInSeconds,
				reconnectTimeUnix: dayjs().unix(),
				reconnectTimeUTC: dayjs().toString(),
			};
			const dataAsString = Object.keys(data).reduce((acc, key) => {
				return `${acc} ${key}=${data[key]},`;
			}, '');

			const storefrontPath = localStorage.getItem(KIOSK_REDIRECT_URL);
			const companyID = localStorage.getItem(KIOSK_COMPANY_ID);

			try {
				logToCore({
					level: LOG_LEVEL.ERROR,
					message: `[KIOSK][NETWORK DISCONNECT] companyID=${
						companyID ?? 'N/A'
					}, storefrontPath=${storefrontPath ?? 'N/A'}, url=${
						router.pathname
					}, browserInfo=${navigator.userAgent}, ${dataAsString}`,
				});
			} catch (error) {
				console.error(error);
			}
			if (networkDisconnected) {
				// refresh the page once it reconnects
				window.location.reload();
			}

			// reset disconnect data
			setDisconnectData({});
			setNetworkDisconnected(false);
		}

		// We only want to run this effect when the network.online value changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [network.online, isKiosk]);

	return {
		isOnline: network.online,
	};
}
