import React from 'react';

import FeatureFlagsContext from 'context/FeatureFlagsContext';
import PropTypes from 'prop-types';

import useProvideFeatureFlags from 'hooks/useProvideFeatureFlags';

export function FeatureFlagsProvider({ children }) {
	const featureFlags = useProvideFeatureFlags();
	return (
		<FeatureFlagsContext.Provider value={featureFlags}>
			{children}
		</FeatureFlagsContext.Provider>
	);
}

FeatureFlagsProvider.propTypes = {
	children: PropTypes.node,
};

export default FeatureFlagsProvider;
