import { useReducer, useCallback, useEffect } from 'react';

import {
	PAYMENTS_DATA,
	IS_PAYMENTS_LOADING,
	PAYMENTS_HAS_ERROR,
	DEFAULT_ID,
	DEFAULT_DROPOFF_ADDRESS,
} from 'constants/consumerAuth.constants';

import {
	setPaymentOptions,
	setIsLoading,
	setError,
	setData,
	editDropoffAddress,
} from 'actions/consumerAuth.actions';

import { reducer, DEFAULT_STATE } from 'reducers/consumerAuth.reducer';

import * as consumerAuthHelper from 'utils/consumerAuth.helper';
import { normalizeByKey } from 'utils/format.helper';

import useAuthToken from 'hooks/useAuthToken';

// Provider hook that creates auth object and handles state
export default function useProvideConsumerAuth() {
	const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);

	const getUserData = useCallback(async () => {
		try {
			dispatch(setError({ hasError: false, message: '' }));
			const newUserData = await consumerAuthHelper.fetchUserData();

			dispatch(
				setData({
					data: {
						user: newUserData,
						isGuest: false,
					},
				}),
			);
		} catch (e) {
			dispatch(setError({ hasError: true, message: e.message }));
		} finally {
			dispatch(setIsLoading({ isLoading: false }));
		}
	}, []);

	const onLogout = useCallback(() => {
		dispatch(setData({ data: { ...DEFAULT_STATE, isLoading: false } }));
	}, []);

	const { login, logout, getAccessToken } = useAuthToken({
		refreshOnPageLoad: true,
		keepTokenAlive: true,
		onLogin: getUserData,
		onLogout,
	});

	useEffect(() => {
		let isSubscribed = true;
		const missingAccessToken = !getAccessToken() || !window;
		(async function () {
			if (isSubscribed) {
				try {
					if (missingAccessToken) {
						dispatch(setIsLoading({ isLoading: false }));
						return;
					}
					await getUserData();
				} catch (error) {
					console.error(error);
				}
			}
		})();

		return () => {
			isSubscribed = false;
		};
	}, [getUserData, getAccessToken]);

	const fetchPaymentOptions = useCallback(async () => {
		dispatch(setPaymentOptions({ key: PAYMENTS_HAS_ERROR, data: false }));
		try {
			dispatch(setPaymentOptions({ key: IS_PAYMENTS_LOADING, data: true }));
			const { default_payment_method_id: defaultID, payment_cards: options } =
				await consumerAuthHelper.fetchPaymentOptions();
			const normalizedOptions = normalizeByKey(options, 'id');
			dispatch(
				setPaymentOptions({ key: PAYMENTS_DATA, data: normalizedOptions }),
			);
			dispatch(setPaymentOptions({ key: DEFAULT_ID, data: defaultID }));
		} catch (e) {
			dispatch(setPaymentOptions({ key: PAYMENTS_HAS_ERROR, data: true }));
		} finally {
			dispatch(setPaymentOptions({ key: IS_PAYMENTS_LOADING, data: false }));
		}
	}, []);

	useEffect(() => {
		(async function () {
			if (state.isGuest || !state.user) return;

			await fetchPaymentOptions();
		})();
	}, [state.isGuest, state.user, fetchPaymentOptions]);

	useEffect(() => {
		if (state.isGuest || !state.user) {
			dispatch(editDropoffAddress(DEFAULT_DROPOFF_ADDRESS));
		} else {
			const dropoffAddress = {
				city: state.user.city,
				state: state.user.state,
				street: state.user.address,
				unit: state.user.address2,
				zip_code: state.user.zipCode,
				latitude: state.user.latitude,
				longitude: state.user.longitude,
				country: state.user.country,
			};
			dispatch(editDropoffAddress(dropoffAddress));
		}
	}, [state.isGuest, state.user]);

	return {
		...state,
		dispatch,
		getUserData,
		login,
		logout,
		paymentOptions: state.paymentOptions,
		fetchPaymentOptions,
		consumerAuthIsLoading: state.isLoading,
	};
}
