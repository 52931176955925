// need this file until create-react-app is updated with a bug fix for color exports
export const appColors = {
	success: '#79eea1',
	info: 'rgba(110, 172, 217, .4)',
	warning: '#faad14',
	danger: '#fa7a68',
	gray: '#a5a5a5',
	inProgress: '#D1ADFF',
	scheduled: '#FEC8FF',
	merchant1: '#b58265',
	merchant2: '#c697c7',
	merchant3: '#9e8ab8',
	merchant4: '#8699a8',
	merchant5: '#89ad95',
	merchant6: '#c2d689',
	publishMenuPublished: '#79eea1',
	publishMenuInProgress: '#6eacd9',
	publishMenuModified: '#faad14',
	publishMenuImported: '#d1adff',
	publishMenuFailed: '#fa7a68',
	publishMenuInactive: '#a5a5a5',
	statusAccepting: ' #f6ffed',
	statusPaused: '#fff1f0',
	newFeature: '#a1ecbb',
	pickupOnly: 'purple',
	secondary: '#4845df',
	primary: '#16173e',
	unavailable: '#faad1499',
	master: '#fff0f6',
	shared: '#FFBE99',
};
