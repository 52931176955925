import {
	SET_DATA,
	SET_IS_LOADING,
	SET_ERROR,
	SET_PAYMENT_OPTIONS,
	EDIT_DROPOFF_ADDRESS,
} from 'constants/consumerAuth.constants';

export const setIsLoading = ({ isLoading }) => ({
	type: SET_IS_LOADING,
	isLoading,
});

export const setError = ({ hasError, message }) => ({
	type: SET_ERROR,
	hasError,
	message,
});

export const setData = ({ data }) => ({
	type: SET_DATA,
	data,
});

export const setPaymentOptions = ({ key, data }) => ({
	type: SET_PAYMENT_OPTIONS,
	key,
	data,
});

export const editDropoffAddress = (data) => ({
	type: EDIT_DROPOFF_ADDRESS,
	data,
});
