import React, { useCallback, useState, createContext } from 'react';

import {
	Button,
	Card,
	Typography,
	message,
	Skeleton,
	Result,
	Popconfirm,
	Alert,
	Tag,
	Dropdown,
	Col,
	Row,
	Tooltip,
} from 'antd';
import PropTypes from 'prop-types';
import { Check, MoreHorizontal } from 'react-feather';

import {
	PAYMENTS_DATA,
	IS_PAYMENTS_LOADING,
	PAYMENTS_HAS_ERROR,
	DEFAULT_ID,
} from 'constants/consumerAuth.constants';

import { setPaymentOptions } from 'actions/consumerAuth.actions';

import {
	createPaymentOption,
	detachPaymentOption,
	setDefaultPaymentOption,
} from 'utils/consumerAuth.helper';
import { formatExpirationDate } from 'utils/helpers';

import { useConsumerAuth } from 'hooks/useConsumerAuth';

const { Text, Title } = Typography;

PaymentOptions.propTypes = {
	children: PropTypes.node,
};
PaymentOptions.Context = createContext();

export default function PaymentOptions({ children, ...rest }) {
	const { paymentOptions } = useConsumerAuth();

	if (paymentOptions[PAYMENTS_HAS_ERROR]) {
		return (
			<Result
				status="500"
				title="Unable to load existing payment methods."
				subTitle="Sorry, something went wrong. Please contact support for assistance."
			/>
		);
	}

	if (paymentOptions[IS_PAYMENTS_LOADING]) {
		return <Skeleton title={false} active paragraph={{ rows: 20 }} />;
	}

	return (
		<PaymentOptions.Context.Provider {...rest}>
			{children}
		</PaymentOptions.Context.Provider>
	);
}

PaymentOptions.AddButton = function AddButton({
	children = '+ Add Payment Method',
	...rest
}) {
	const [addButtonIsLoading, setAddButtonIsLoading] = useState(false);

	const addPaymentMethod = useCallback(async () => {
		try {
			setAddButtonIsLoading(true);
			const { url } = await createPaymentOption();

			window.location.replace(url);
		} catch (e) {
			message.error('Unable to create payment setup session.');
			console.error(e);
			setAddButtonIsLoading(false);
		}
	}, []);

	return (
		<Button
			data-cy="add-payment-card-button"
			className="mt-10"
			type="link"
			onClick={addPaymentMethod}
			loading={addButtonIsLoading}
			{...rest}
		>
			{children}
		</Button>
	);
};
PaymentOptions.AddButton.propTypes = {
	children: PropTypes.node,
};

PaymentOptions.RemoveButton = function RemoveButton({
	paymentMethodID,
	children = 'Remove',
	...rest
}) {
	const { paymentOptions, dispatch } = useConsumerAuth();
	const removePaymentMethod = useCallback(
		async (paymentMethodID) => {
			try {
				dispatch(setPaymentOptions({ key: IS_PAYMENTS_LOADING, data: true }));
				await detachPaymentOption(paymentMethodID);
				const updatedPaymentOptions = { ...paymentOptions[PAYMENTS_DATA] };
				delete updatedPaymentOptions[paymentMethodID];
				dispatch(
					setPaymentOptions({
						key: PAYMENTS_DATA,
						data: updatedPaymentOptions,
					}),
				);
			} catch (e) {
				message.error('Unable to remove payment method. Please try again.');
				console.error(e);
			} finally {
				dispatch(setPaymentOptions({ key: IS_PAYMENTS_LOADING, data: false }));
			}
		},
		[dispatch, paymentOptions],
	);

	return (
		<Popconfirm
			title="Are you sure you want to remove this payment method?"
			onConfirm={() => removePaymentMethod(paymentMethodID)}
			okButtonProps={{ 'data-cy': `confirm-remove-${paymentMethodID}` }}
			{...rest}
		>
			<Button
				size="small"
				type="text"
				data-cy={`remove-payment-button-${paymentMethodID}`}
			>
				{children}
			</Button>
		</Popconfirm>
	);
};

PaymentOptions.RemoveButton.propTypes = {
	paymentMethodID: PropTypes.string.isRequired,
	children: PropTypes.node,
};

PaymentOptions.SetDefaultButton = function SetDefaultButton({
	paymentMethodID,
	children = 'Set as default',
	...rest
}) {
	const { paymentOptions, dispatch } = useConsumerAuth();
	const setDefaultPaymentMethod = useCallback(
		async (paymentMethodID) => {
			try {
				dispatch(setPaymentOptions({ key: IS_PAYMENTS_LOADING, data: true }));
				await setDefaultPaymentOption(paymentMethodID);
				dispatch(
					setPaymentOptions({
						key: DEFAULT_ID,
						data: paymentMethodID,
					}),
				);
			} catch (e) {
				message.error(
					'Unable to set default payment method. Please try again.',
				);
				console.error(e);
			} finally {
				dispatch(setPaymentOptions({ key: IS_PAYMENTS_LOADING, data: false }));
			}
		},
		[dispatch],
	);

	return (
		<Button
			onClick={() => setDefaultPaymentMethod(paymentMethodID)}
			size="small"
			type="text"
			data-cy={`set-default-payment-button-${paymentMethodID}`}
			disabled={paymentOptions[DEFAULT_ID] === paymentMethodID}
			{...rest}
		>
			{children}
		</Button>
	);
};

PaymentOptions.SetDefaultButton.propTypes = {
	paymentMethodID: PropTypes.string.isRequired,
	children: PropTypes.node,
};

PaymentOptions.Overlay = function Overlay({ paymentMethodID }) {
	return [
		{
			key: 'default',
			label: PaymentOptions.SetDefaultButton({ paymentMethodID }),
		},
		{ key: 'remove', label: PaymentOptions.RemoveButton({ paymentMethodID }) },
	];
};
PaymentOptions.Overlay.propTypes = {
	paymentMethodID: PropTypes.string.isRequired,
};

PaymentOptions.CardDropDown = function CardDropDown({
	paymentMethodID,
	...rest
}) {
	return (
		<Dropdown
			menu={{ items: PaymentOptions.Overlay({ paymentMethodID }) }}
			trigger={['click']}
			{...rest}
		>
			<Button
				data-cy={`payment-card-more-actions-${paymentMethodID}`}
				icon={<MoreHorizontal size={22} />}
				type="link"
				onClick={(e) => e.preventDefault()}
			/>
		</Dropdown>
	);
};
PaymentOptions.CardDropDown.propTypes = {
	paymentMethodID: PropTypes.string.isRequired,
};

PaymentOptions.BrandTag = function BrandTag({
	brand,
	color = 'blue',
	...rest
}) {
	return (
		<Tag data-cy={`payment-card-brand`} color={color} className="tag" {...rest}>
			{brand.toUpperCase()}
		</Tag>
	);
};
PaymentOptions.BrandTag.propTypes = {
	brand: PropTypes.string.isRequired,
	color: PropTypes.string,
};
PaymentOptions.NoneAlert = function NoneAlert({
	message = 'No existing payment methods found.',
	...rest
}) {
	const { paymentOptions } = useConsumerAuth();
	return !Object.keys(paymentOptions[PAYMENTS_DATA] ?? {}).length ? (
		<Alert
			type="warning"
			message={message}
			showIcon
			data-cy="no-payment-options-alert"
			{...rest}
		/>
	) : null;
};
PaymentOptions.NoneAlert.propTypes = {
	message: PropTypes.string,
};

PaymentOptions.CardList = function CardList({ ...rest }) {
	const { paymentOptions } = useConsumerAuth();
	return (
		<div {...rest}>
			{Object.values(paymentOptions[PAYMENTS_DATA]).map(({ id, card }) => (
				<Card
					data-cy={`payment-option-card-${id}`}
					className="mb-10 payment-method__card"
					size="small"
					key={id}
					extra={
						<>
							<PaymentOptions.BrandTag brand={card?.brand} />
							<PaymentOptions.CardDropDown paymentMethodID={id} />
						</>
					}
				>
					<Row>
						<Col span={22}>
							<Title level={5}>************{card.last4}</Title>
						</Col>
						<Col span={2}>
							{id === paymentOptions[DEFAULT_ID] && (
								<Tooltip title="Default">
									<Check data-cy={`default-payment-option__icon-${id}`} />
								</Tooltip>
							)}
						</Col>
					</Row>
					<Text>
						Exp.&nbsp;
						{formatExpirationDate({
							month: card.exp_month,
							year: card.exp_year,
						})}
					</Text>
				</Card>
			))}
		</div>
	);
};
