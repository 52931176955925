import { useMemo } from 'react';

import queryString from 'query-string';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import { formatURL } from 'utils/format.helper';

export function useRouter() {
	const params = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	// Return our custom router object
	// Memoize so that a new object is only returned if something changes
	return useMemo(() => {
		const searchParams = new URLSearchParams(location.search);
		// Merge params and parsed query string into single "query" object
		// so that they can be used interchangeably.
		// Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
		const formattedLocationSearch = { ...queryString.parse(location.search) }; // convert string to object
		const query = {
			...formattedLocationSearch,
			...params,
			...searchParams,
		};

		const push = (route, newParams) => {
			return navigate(formatURLWithPreserve(route, newParams), {
				replace: true,
			});
		};

		// When we history.push, we only preserve the company ID / Merchant ID / DateRange
		// Otherwise, they need to be explicitly passed in
		const formatURLWithPreserve = (route, newParams) => {
			if (!route) {
				return;
			}
			return formatURL(route, {
				companyID: query.companyID,
				merchantID: query.merchantID,
				dateRange: query.dateRange,
				multiCompany: query.multiCompany,
				multiMerchant: query.multiMerchant,
				multiSelect: query.multiSelect,
				...newParams,
			});
		};

		return {
			// For convenience add push(), replace(), pathname at top level
			push,
			pathname: location.pathname,
			query,
			// Include location, history objects so we have
			// access to extra React Router functionality if needed.
			location,
			navigate,
			searchParams,
			// auto format the route on push
			formatURL: formatURLWithPreserve,
			formattedLocationSearch,
		};
	}, [params, location, navigate]);
}
