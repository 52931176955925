import { useMemo, useReducer, useEffect, useRef } from 'react';

import dayjs from 'dayjs';
import Pusher from 'pusher-js';

import { KIOSK, KIOSK_REFRESH_TIME } from 'constants/kiosk.constants';
import { REFRESH_KIOSK_APP } from 'constants/menuCheckout.constants';

import { reducer, DEFAULT_STATE } from 'reducers/kiosk.reducer';

export default function useProvideKiosk() {
	const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);
	const pusher = useRef();

	const isKiosk = useMemo(
		() => window.location.host.split('.')[0].includes(KIOSK),
		[],
	);

	// every minute check the time
	// if it's 4am locally, refresh the browser
	useEffect(() => {
		const interval = setInterval(() => {
			const localTime = dayjs().format('HH:mm');
			if (isKiosk && localTime === KIOSK_REFRESH_TIME) {
				clearInterval(interval);
				window.location.reload();
			}
		}, 60000);

		return () => {
			clearInterval(interval);
		};
	}, [isKiosk]);

	useEffect(() => {
		if (!isKiosk) return;

		if (!pusher.current) {
			pusher.current = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
				cluster: process.env.REACT_APP_PUSHER_CLUSTER,
			});
		}
		const globalChannelName = `${process.env.REACT_APP_WS_STAGE}-cuboh-kiosk`;
		const globalChannel = pusher.current.subscribe(globalChannelName);
		globalChannel.bind_global((event) => {
			if (event === REFRESH_KIOSK_APP) {
				setTimeout(() => {
					window.location.reload(true);
				}, Math.floor(Math.random() * 30000));
			}
		});
	}, [isKiosk]);

	return {
		isKiosk,
		dispatch,
		kioskRedirectURL: state.kioskRedirectURL,
		kioskCompanyID: state.kioskCompanyID,
		kioskTerminalID: state.kioskTerminalID,
		sessionID: state.sessionID,
	};
}
