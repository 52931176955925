import { useEffect, useState } from 'react';

import { fetchFeatureFlags } from 'utils/helpers';

export default function useProvideFeatureFlags() {
	const [featureFlags, setFeatureFlags] = useState({});
	const [featureFlagsLoading, setFeatureFlagsLoading] = useState(true);

	useEffect(() => {
		(async () => {
			setFeatureFlagsLoading(true);
			try {
				const response = await fetchFeatureFlags();
				setFeatureFlags(response);
			} catch (error) {
				console.error(error);
			} finally {
				setFeatureFlagsLoading(false);
			}
		})();
	}, []);

	return {
		featureFlags,
		featureFlagsLoading,
	};
}
