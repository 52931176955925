import React from 'react';

import NetworkContext from 'context/NetworkContext';
import PropTypes from 'prop-types';

import useProvideNetwork from 'hooks/useProvideNetwork';

export function NetworkProvider({ children }) {
	const value = useProvideNetwork();
	return (
		<NetworkContext.Provider value={value}>{children}</NetworkContext.Provider>
	);
}

NetworkProvider.propTypes = {
	children: PropTypes.node,
};

export default NetworkProvider;
