import { message } from 'antd';
import axios from 'axios';

import { ACCESS } from 'constants/constants';
import { PERMISSION_ERROR } from 'constants/consumerAuth.constants';

export async function createCoreAxiosInstance(access = null) {
	const accessToken = access ?? localStorage.getItem(ACCESS);
	const instance = axios.create({
		baseURL: process.env.REACT_APP_SERVER_URL,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: accessToken ? 'Bearer ' + accessToken : null,
		},
	});

	// If we encounter a permissions error from core - have a flash message appear.
	instance.interceptors.response.use(
		(response) => response,
		(error) => {
			if (error.response.status === 403) {
				message.warning(PERMISSION_ERROR);
			}

			throw error;
		},
	);

	return instance;
}

export async function createMenuManagementAxiosInstance() {
	return axios.create({
		baseURL: process.env.REACT_APP_MENU_MANAGEMENT_URL,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: localStorage.getItem(ACCESS)
				? 'Bearer ' + localStorage.getItem(ACCESS)
				: null,
		},
	});
}

export async function createAssetAxiosInstance() {
	return axios.create({
		baseURL: process.env.REACT_APP_ASSET_URL,
		headers: {},
	});
}

export async function createGoogleAxiosInstance() {
	return axios.create({
		baseURL: process.env.REACT_APP_GOOGLE_BASE_URL,
		headers: { Accept: 'text/plain', 'Content-Type': 'text/plain' },
	});
}
