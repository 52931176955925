import 'es6-shim';
import React from 'react';

import { ConfigProvider, message } from 'antd';
import App from 'App';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import HttpsRedirect from 'react-https-redirect';
import { BrowserRouter as Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-dom-last-location';

import { ENVIRONMENT } from 'constants/config.constants';

const tagManagerArgs = {
	gtmId: 'GTM-W2NL564',
};
message.config({
	duration: 4,
	maxCount: 3,
	prefixCls: 'cuboh-message',
});

export default function Root() {
	return (
		<HttpsRedirect disabled={process.env.NODE_ENV === 'development'}>
			<Helmet>
				{process.env.REACT_APP_ENVIRONMENT === ENVIRONMENT.production &&
					TagManager.initialize(tagManagerArgs)}
				<script
					async
					src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=maps,places&callback=Function.prototype`}
				/>
			</Helmet>
			<Router>
				<LastLocationProvider>
					<ConfigProvider prefixCls={'cuboh'}>
						<App />
					</ConfigProvider>
				</LastLocationProvider>
			</Router>
		</HttpsRedirect>
	);
}
