import React from 'react';

import ConsumerAuthContext from 'context/ConsumerAuthContext';
import PropTypes from 'prop-types';

import useProvideConsumerAuth from 'hooks/useProvideConsumerAuth';

export function ConsumerAuthProvider({ children }) {
	const consumerAuth = useProvideConsumerAuth();
	return (
		<ConsumerAuthContext.Provider value={consumerAuth}>
			{children}
		</ConsumerAuthContext.Provider>
	);
}

ConsumerAuthProvider.propTypes = {
	children: PropTypes.node,
};

export default ConsumerAuthProvider;
