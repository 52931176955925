import {
	EDIT_KIOSK_COMPANY_ID,
	EDIT_KIOSK_TERMINAL_ID,
	EDIT_KIOSK_REDIRECT_URL,
	EDIT_KIOSK_SESSION_ID,
	CLEAR_KIOSK_KEYS,
	KIOSK_REDIRECT_URL,
	KIOSK_COMPANY_ID,
	KIOSK_TERMINAL_ID,
} from 'constants/kiosk.constants';

// adding key word "kiosk" so not to be confused with the storefront data
export const DEFAULT_STATE = {
	kioskRedirectURL: localStorage.getItem(KIOSK_REDIRECT_URL),
	kioskCompanyID: isNaN(parseInt(localStorage.getItem(KIOSK_COMPANY_ID)))
		? null
		: parseInt(localStorage.getItem(KIOSK_COMPANY_ID)),
	kioskTerminalID: localStorage.getItem(KIOSK_TERMINAL_ID),
	sessionID: null,
};

export const reducer = (state, action) => {
	switch (action.type) {
		case EDIT_KIOSK_COMPANY_ID:
			if (action.value === null) {
				window.localStorage.removeItem(KIOSK_COMPANY_ID);
			} else {
				window.localStorage.setItem(KIOSK_COMPANY_ID, action.value);
			}
			return {
				...state,
				kioskCompanyID: action.value,
			};
		case EDIT_KIOSK_TERMINAL_ID:
			if (action.value === null) {
				window.localStorage.removeItem(KIOSK_TERMINAL_ID);
			} else {
				window.localStorage.setItem(KIOSK_TERMINAL_ID, action.value);
			}

			return {
				...state,
				kioskTerminalID: action.value,
			};
		case EDIT_KIOSK_REDIRECT_URL:
			if (action.value === null) {
				window.localStorage.removeItem(KIOSK_REDIRECT_URL);
			} else {
				window.localStorage.setItem(KIOSK_REDIRECT_URL, action.value);
			}

			return {
				...state,
				kioskRedirectURL: action.value,
			};
		case EDIT_KIOSK_SESSION_ID:
			return {
				...state,
				sessionID: action.value,
			};
		case CLEAR_KIOSK_KEYS:
			window.localStorage.removeItem(KIOSK_COMPANY_ID);
			window.localStorage.removeItem(KIOSK_TERMINAL_ID);
			window.localStorage.removeItem(KIOSK_REDIRECT_URL);
			return {
				...state,
				kioskCompanyID: null,
				kioskTerminalID: null,
				kioskRedirectURL: null,
			};
		default:
			return state;
	}
};
