import React from 'react';

import KioskContext from 'context/KioskContext';
import PropTypes from 'prop-types';

import useProvideKiosk from 'hooks/useProvideKiosk';

export function KioskProvider({ children }) {
	const kiosk = useProvideKiosk();
	return (
		<KioskContext.Provider value={kiosk}>{children}</KioskContext.Provider>
	);
}

KioskProvider.propTypes = {
	children: PropTypes.node,
};

export default KioskProvider;
