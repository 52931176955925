import { createCoreAxiosInstance } from 'createAxiosInstance';

import { ACCESS, REFRESH } from 'constants/constants';

export const login = async (values) => {
	const axiosInstance = await createCoreAxiosInstance();
	const { data } = await axiosInstance.post('/api/v2/login', values);
	return data;
};

export const handleForgotPasswordRequest = async (values) => {
	[ACCESS, REFRESH].forEach((key) => localStorage.removeItem(key));
	const axiosInstance = await createCoreAxiosInstance();
	await axiosInstance.post(`/password_reset/`, values);
};

/**
 * Creates a valid path name for a given name,
 * used when creating the storefront route
 *
 * @example "Simba's Place 1" -> "simbas-place-1"
 *
 * @param name: The name to convert to a path
 * @returns URL path version of the name
 */
export const toPathName = (name) =>
	(name ?? '')
		.replace(/&/g, 'and')
		.replace(/[^A-Za-z0-9- ]/g, '')
		.replace(/-/g, ' ')
		.replace(/\s\s+/g, ' ')
		.replace(/\s/g, '-')
		.toLowerCase();

export const refreshAuthToken = async ({
	refreshToken,
	accessToken = null,
}) => {
	const axios = await createCoreAxiosInstance(accessToken);
	const { data } = await axios.post('api/v2/token/refresh', {
		refresh: refreshToken,
	});
	return data.access;
};

export const fetchKioskUser = async (accessToken) => {
	const axiosInstance = await createCoreAxiosInstance(accessToken);
	const {
		data: { response },
	} = await axiosInstance.get('/api/kiosk/user');
	return response;
};
