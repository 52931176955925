import {
	SET_IS_LOADING,
	SET_ERROR,
	SET_DATA,
	SET_PAYMENT_OPTIONS,
	PAYMENTS_HAS_ERROR,
	PAYMENTS_DATA,
	DEFAULT_ID,
	IS_PAYMENTS_LOADING,
	DEFAULT_DROPOFF_ADDRESS,
	EDIT_DROPOFF_ADDRESS,
} from 'constants/consumerAuth.constants';

export const DEFAULT_STATE = {
	isLoading: true,
	hasError: null,
	user: null,
	isGuest: true,
	paymentOptions: {
		[PAYMENTS_HAS_ERROR]: null,
		[IS_PAYMENTS_LOADING]: false,
		[PAYMENTS_DATA]: {},
		[DEFAULT_ID]: null,
	},
	dropoffAddress: DEFAULT_DROPOFF_ADDRESS,
};

export const reducer = (state, action) => {
	switch (action.type) {
		case SET_IS_LOADING:
			return { ...state, isLoading: action.isLoading };
		case SET_ERROR:
			return { ...state, hasError: action.hasError, message: action.message };
		case SET_DATA:
			return { ...state, ...action.data };
		case SET_PAYMENT_OPTIONS:
			return {
				...state,
				paymentOptions: { ...state.paymentOptions, [action.key]: action.data },
			};
		case EDIT_DROPOFF_ADDRESS:
			return {
				...state,
				dropoffAddress: { ...state.dropoffAddress, ...action.data },
			};
		default:
			return { ...state };
	}
};
