import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
	useMemo,
} from 'react';

import { Button, Form, Space, message, Result, Typography } from 'antd';
import { createCoreAxiosInstance } from 'createAxiosInstance';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { USER_TYPE } from 'constants/config.constants';
import { ACCESS, REFRESH } from 'constants/constants';

import { useRouter } from 'hooks/useRouter';

import SetPassword from 'components/SetPassword';

ResetPasswordForm.propTypes = {
	userType: PropTypes.oneOf([USER_TYPE.restaurant, USER_TYPE.consumer]),
};

const { PasswordInput, ConfirmPasswordInput, Validation } = SetPassword;
const { Text } = Typography;

export default function ResetPasswordForm({ userType }) {
	const [form] = Form.useForm();
	const { query, push } = useRouter();
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const token = useRef(query.token);

	const loginRoute = useMemo(
		() => (userType === USER_TYPE.consumer ? '/store/login' : '/login'),
		[userType],
	);

	useEffect(() => {
		(async () => {
			if (token.current === null) {
				push(loginRoute);
				return;
			}
			// Verify that the token is valid (not already used)
			try {
				const axiosInstance = await createCoreAxiosInstance();
				await axiosInstance.post(`/password_reset/validate_token/`, {
					token: token.current,
				});
			} catch (error) {
				push(loginRoute);
			}
		})();
	}, [push, token, loginRoute]);

	const onFinish = useCallback(async (values) => {
		try {
			[ACCESS, REFRESH].forEach((key) => localStorage.removeItem(key));
			const axiosInstance = await createCoreAxiosInstance();
			await axiosInstance.post(`/password_reset/confirm/`, {
				...values,
				token: token.current,
			});
			message.success('Password Successfully Reset');
			setIsFormSubmitted(true);
		} catch (error) {
			const { data } = error.response;
			message.error(data.password);
		}
	}, []);

	const onFinishFailed = useCallback((errorInfo) => {
		console.error(errorInfo);
		message.error(`Failed to reset your password.`);
	}, []);

	const submitButtonDisabled = useCallback((form) => {
		return (
			!form.getFieldValue('password') ||
			!form.getFieldValue('confirmPassword') ||
			form.getFieldsError().filter(({ errors }) => errors.length).length > 0
		);
	}, []);

	if (isFormSubmitted) {
		return (
			<Result
				status="success"
				title={<Text>Your password has been reset.</Text>}
				extra={[
					<Link key="login" to={loginRoute}>
						<Button type="primary" block data-cy="reset-password__login">
							Login
						</Button>
					</Link>,
				]}
			/>
		);
	}

	return (
		<Form
			name="reset-password"
			form={form}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
		>
			<Space direction="vertical" className="full-width" size="small">
				<SetPassword>
					<PasswordInput data-cy="reset-password__password" />
					<ConfirmPasswordInput data-cy="reset-password__confirm-password" />
					<Validation />
				</SetPassword>
				<Form.Item shouldUpdate>
					{() => (
						<Button
							size="middle"
							block
							type="primary"
							htmlType="submit"
							data-cy="reset-password__submit"
							disabled={submitButtonDisabled(form)}
						>
							Reset Password
						</Button>
					)}
				</Form.Item>
			</Space>
		</Form>
	);
}
