import React, { createContext, useContext, useState } from 'react';

import { Form, Input, Row, Col } from 'antd';
import PropTypes from 'prop-types';

import { PASSWORD_RULES } from 'constants/consumerAuth.constants';

import BooleanIcon from 'components/BooleanIcon';

const { Password } = Input;
const { Item } = Form;

SetPassword.propTypes = {
	rules: PropTypes.arrayOf(PropTypes.object),
	children: PropTypes.node,
};

SetPassword.Context = createContext();

function SetPassword({ rules = PASSWORD_RULES, children }) {
	const [password, setPassword] = useState(null);

	return (
		<SetPassword.Context.Provider value={{ password, setPassword, rules }}>
			{children}
		</SetPassword.Context.Provider>
	);
}

SetPassword.Validation = function Validation() {
	const { password, rules } = useContext(SetPassword.Context);

	return (
		<Row className="register__password-rules">
			{rules.map(({ condition, label }) => (
				<Col key={label} span={24}>
					<BooleanIcon condition={condition(password)} label={label} />
				</Col>
			))}
		</Row>
	);
};

SetPassword.PasswordInput = function PasswordInput({ ...rest }) {
	const { rules, setPassword } = useContext(SetPassword.Context);

	return (
		<Item
			name="password"
			rules={[
				{ max: 100 },
				{
					required: true,
					message: 'Please input your password',
				},
				() => ({
					validator(_, value) {
						const failedCondition = rules
							.map(({ condition }) => condition(value))
							.find((condition) => !condition);
						if (failedCondition === undefined || !value) {
							return Promise.resolve();
						}

						return Promise.reject(
							'Password does not meet all of the required criteria',
						);
					},
				}),
			]}
			{...rest}
		>
			<Password
				data-cy="set-password__password"
				placeholder="Password"
				onChange={(e) => setPassword(e.target.value)}
				required
			/>
		</Item>
	);
};

SetPassword.ConfirmPasswordInput = function ConfirmPasswordInput({ ...rest }) {
	return (
		<Item
			name="confirmPassword"
			required
			dependencies={['password']}
			rules={[
				{
					required: true,
					message: 'Please confirm your password',
				},
				({ getFieldValue }) => ({
					validator(_, value) {
						if (getFieldValue('password') !== value) {
							return Promise.reject('Passwords do not match');
						}
						return Promise.resolve();
					},
				}),
			]}
			{...rest}
		>
			<Password
				placeholder="Confirm Password"
				data-cy="set-password__confirm-password"
			/>
		</Item>
	);
};

export default SetPassword;
