import { createCoreAxiosInstance } from 'createAxiosInstance';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { PhoneNumberUtil } from 'google-libphonenumber';

dayjs.extend(utc);
dayjs.extend(timezone);

export function formatCurrency(value) {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	});

	return formatter.format(value);
}

export const atLeastOneNumber = (value) => !!value?.match(/(?=.*\d)/);
export const atLeastOneLowerCase = (value) => !!value?.match(/.*[a-z].*/);
export const atleastOneUpperCase = (value) => !!value?.match(/.*[A-Z].*/);
export const noEmptySpaces = (value) => value && !value?.match(/(?=.*\s)/);
export const atLeastOneSpecialCharacter = (value) =>
	!!value?.match(/(?=.*[!#$%&+-=?@^_~])/);
export const atLeastTenCharactersLong = (value) => !!value?.match(/.{10,}/);

export const toTitleCase = (word) => {
	if (!word) {
		return '';
	}
	return `${word.charAt(0).toUpperCase()}${word.substr(1).toLowerCase()}`;
};

export const formatScheduleTime = (time) => {
	const [hour, minute] = time.split(':');
	const hourInt = parseInt(hour) % 24;

	// 0:30 -> 12:30 AM
	if (hourInt === 0) {
		return `12:${minute} AM`;
	}

	// 07:30 -> 7:30 AM
	if (hourInt < 12) {
		return `${hourInt}:${minute} AM`;
	}

	// 12:30 -> 12:30 PM
	if (hourInt === 12) {
		return `${hourInt}:${minute} PM`;
	}

	// 14:30 -> 2:30:pm
	return `${hourInt - 12}:${minute} PM`;
};

export const convertStringToCypressDataCy = (string = ' ') => {
	return string.trim().replace(/ /g, '-').toLowerCase();
};

export const capitalizeWords = (string) => {
	return string.replace(/(?:^|\s)\S/g, (a) => {
		return a.toUpperCase();
	});
};

export const valueIsPresent = (value) => {
	return value && !['Undefined', 'Undefiend', '', 'None'].includes(value);
};

export const phoneIsPresent = (phone) => {
	return phone && phone.length > 2 && !['Undefined'].includes(phone);
};

/**
 * Parse a phone number for the antd phone number input initial state
 *
 * @example
 * '+1 250 555 5555' -> { short: 'CA', code: '1' phone: '5555555', isValid: true  }
 *
 * @param {string} phoneNumber: A phone number string '+1 250 555 5555'
 * @returns An object splitting the phone number into its parts
 */
export const parsePhoneNumber = (phoneNumber) => {
	const phoneNumberUtil = PhoneNumberUtil.getInstance();
	try {
		const parsedPhoneNumber = phoneNumberUtil.parseAndKeepRawInput(phoneNumber);
		const nationalNumber = parsedPhoneNumber.getNationalNumber().toString();
		const areaCode = nationalNumber.substring(0, 3) || '';
		const telephonePrefix = nationalNumber.substring(3, 6) || '';
		const lineNumber = nationalNumber.substring(6, 10) || '';
		return {
			short: phoneNumberUtil.getRegionCodeForNumber(parsedPhoneNumber),
			phone: `${areaCode}-${telephonePrefix}-${lineNumber}`,
			code: parsedPhoneNumber.getCountryCode(),
			isValid: phoneNumberUtil.isValidNumber(parsedPhoneNumber),
		};
	} catch (e) {
		return {
			short: 'CA',
			phone: phoneNumber,
			code: 1,
			isValid: false,
		};
	}
};

/**
 * Format a phone number for user display
 *
 * @param {string} phoneNumber: A phone number string +12505556666
 * @returns A formatted phone number string +X YYY-ZZZ-ZZZZ
 */
export const formatPhoneNumber = (phoneNumberString) => {
	const parsedPhoneNumber = parsePhoneNumber(phoneNumberString);
	return `+${parsedPhoneNumber.code} ${parsedPhoneNumber.phone}`;
};

/**
 * Take a phone number object from antd phone input and put to a string
 *
 * @example
 * `{ code: 1, phone: '(800) 900 7000' }` -> '+18009007000`
 *
 * @param {object} phoneNumberObject: object with code and phone number
 * @returns {string} Valid phone number string in E.164 format
 */
export const phoneNumberToString = ({ code, phone }) =>
	`+${code || ''}${phone?.replace(/[\D]/g, '') || ''}`;

export const formatExpirationDate = ({ month, year }) => {
	return `${month < 10 ? `0${month}` : month}/${year % 100}`;
};

export const logToCore = async ({ level, message, alert = false }) => {
	const axios = await createCoreAxiosInstance();
	const extra = alert ? { alert: true } : null;
	await axios.post('/logging', {
		level,
		message,
		extra,
	});
};

// if the end is before the start
// it rolls over to the next day
export const isEndTimePastMidnight = ({ start, end }) => {
	const startTimeAsDate = new Date(`01/01/1970 ${start}`);
	const startTimeValidDate =
		startTimeAsDate instanceof Date && !isNaN(startTimeAsDate);

	const endTimeAsDate = new Date(`01/01/1970 ${end}`);
	const endTimeValidDate =
		endTimeAsDate instanceof Date && !isNaN(endTimeAsDate);

	if (startTimeValidDate && endTimeValidDate) {
		return endTimeAsDate - startTimeAsDate < 0;
	}
	return false;
};

export const fetchFeatureFlags = async () => {
	try {
		const axiosInstance = await createCoreAxiosInstance();
		const { data } = await axiosInstance.get('/api/v2/feature-flags');
		return data;
	} catch (error) {
		console.error(error);
		throw new Error(error.message);
	}
};
