export const ENVIRONMENT = {
	dev: 'dev',
	production: 'production',
	local: 'local',
};

export const USER_TYPE = {
	restaurant: 'restaurant',
	consumer: 'consumer',
};
